<template>
  <v-tooltip v-bind="$attrs">
    <template v-slot:activator="{ on }">
      <span v-on="on" :style="smallFont ? 'font-size: 0.75rem' : ''" v-bind="labelAttrs">
        {{ fromNow(date, withoutSuffix) }}
      </span>
    </template>
    <span>
      {{ formatTimestamp(date, (fmt = 'std')) }}
    </span>
  </v-tooltip>
</template>

<script>
import utils from '@/mixins/utils';
export default {
  mixins: [utils],
  props: {
    date: {
      required: true
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD h:m A'
    },
    withoutSuffix: {
      type: Boolean,
      default: false
    },
    smallFont: {
      type: Boolean,
      default: true
    },
    labelAttrs: {
      type: Object,
      deafult: () => {
        return {};
      }
    }
  }
};
</script>

<style></style>
